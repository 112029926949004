import React from "react";
import { Row, Col } from "react-bootstrap";
import { Container, Text } from "../styles/styles";
import Layout from "../components/Layout";
import ErrorSvg from "../../static/404.svg"

const ErrorPage = () => {

  
    return (
      <>
        <Layout>
          <Container>
            <Row>
              <Col xs={6}>
                <img alt="Error 404" style={{width: "100%"}} src={ErrorSvg} />
              </Col>
              <Col xs={6} className="d-flex align-items-center">
                <Text className="mt-5">An error ocurred.</Text>
              </Col>
            </Row>
          </Container>
        </Layout>
      </>
    );
};
ErrorPage.defaultProps = {};

ErrorPage.propTypes = {};

export default ErrorPage